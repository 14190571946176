<template>
    <div>
        <van-row @click="openModal">
            <van-col span="6">
                <img :src="avatarSrc">
            </van-col>
            <van-col span="18">
                <van-button round size="large">{{nickname}}</van-button>
            </van-col>
        </van-row>


        <!-- <van-grid :column-num="3" square>
          <van-grid-item icon="label-o" text="我的课程" />
          <van-grid-item icon="bill-o" text="我的笔记" />
          <van-grid-item icon="gift-card-o" text="课程答疑" />
        </van-grid> -->

        <van-grid :column-num="3">
            <van-grid-item v-for="(item, index) in squareLink" :key="index" class="squareLink"
                           @click="handler(item.link)">
                <template #text>
                    <div class="textTitle">{{item.text}}</div>
                </template>
                <template #icon>
                    <van-icon :name="item.photo"/>
                </template>
            </van-grid-item>
            {{ item }}
        </van-grid>
        <div class="horizontalLink">
            <van-cell class="typeList" center v-for="(item, index) in horizontalLink" :key="index"
                      @click="handler(item.link)" :value="item.desc">
                <template #title>
                    <span class="custom-title">{{item.text}}</span>
                </template>
                <template #icon>
                    <van-icon :name="item.photo"/>
                </template>
                <template #right-icon>
                    <van-icon name="arrow"/>
                </template>
                <!-- <template #value>
                    <span class="value-title">{{item.desc}}</span>
                </template> -->
            </van-cell>
        </div>
        <div class="signOut" v-if="isLogin">
            <van-button round size="large" icon="exchange" @click="loginOut">退出登录</van-button>
        </div>

        <transition name="van-fade">
            <div class="login-modal" v-show="isShowModal">
                <div class="close-modal" @click="isShowModal=!isShowModal"></div>
                        <van-form @submit="onSubmit" class="login-form" v-show="isShowLogin">
                            <div class="loginTitle">账号密码登录</div>
                            <van-field
                                    v-model="username"
                                    name="usernameLogin"
                                    label="用户名"
                                    placeholder="用户名"
                                    :rules="[{ required: true,}]"
                            />
                            <van-field
                                    v-model="password"
                                    type="password"
                                    name="passwordLogin"
                                    label="密码"
                                    placeholder="密码"
                                    :rules="[{ required: true,}]"
                            />
                            <van-field name="agreement">
                                <template #input>
                                    <van-radio-group v-model="checked" @change="changeFn">
                                        <van-radio name="1" shape="square"  @click="clickFn">
                                            <div class="title">我已阅读并同意：</div>
                                            <span class="tit" @click="servicesBtn">《网校服务条款》</span>
                                            <span class="tit" @click="agreementBtn">《用户隐私协议》</span>
                                        </van-radio>
                                    </van-radio-group>
                                </template>
                            </van-field>
                            <div style="margin: 2rem;">
                                <van-button round block type="info" size="large" native-type="submit">登录</van-button>
                            </div>
                            <div class="register"  @click="registerFn">
                                <van-button round block >没有账号？<span class="text">点击注册</span></van-button>
                            </div>
                        </van-form>
                        <van-form @submit="onRegister" class="login-form" v-show="isShowReg">
                            <div class="loginTitle">新账号注册</div>
                            <van-field
                                    v-model="usernameRegister"
                                    name="usernameRegister"
                                    label="账号"
                                    placeholder="请填写学习账号"
                                    :rules="[{ required: true,}]"
                            />
                            <van-field
                                    v-model="passwordRegister"
                                    name="passwordRegister"
                                    label="密码"
                                    placeholder="请输入密码"
                                    :rules="[{ required: true, }]"
                            />
                            <van-field
                                    v-model="passwordRegister2"
                                    type="password"
                                    name="passwordRegister2"
                                    label="确认密码"
                                    placeholder="请再次确认密码"
                                    :rules="[{ required: true, }]"
                            />
                            <van-field name="agreement2">
                                <template #input>
                                    <van-radio-group v-model="checked" @change="changeFn">
                                        <van-radio name="1" shape="square"  @click="clickFn">
                                            <div class="title">我已阅读并同意：</div>
                                            <span class="tit" @click="servicesBtn">《网校服务条款》</span>
                                            <span class="tit" @click="agreementBtn">《用户隐私协议》</span>
                                        </van-radio>
                                    </van-radio-group>
                                </template>
                            </van-field>

                            <div style="margin: 16px;">
                                <van-button round block type="info" size="large" native-type="submit">同意以上协议并注册</van-button>
                            </div>
                            <div class="register"  @click="registerFn">
                                <van-button round block >已有账号？<span class="text">返回登录</span></van-button>
                            </div>
                        </van-form>
            </div>
        </transition>
    </div>
</template>

<script>
    import {courseList, GoLogin,onInfo,GoRegister } from '@/request/api'
    import {Toast} from "vant";
    let redirectUrl;
    export default {
        beforeRouteEnter(to, from, next) {
            if (to.query && to.query.redirect) {
                redirectUrl = to.query.redirect
            }
            next();
        },
        metaInfo() {
            return {
                title: this.title+'-我的', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                avatarSrc: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/avatar.png",
                username: '',
                usernameRegister: '',
                password: '',
                passwordRegister: '',
                passwordRegister2: '',
                isShowModal: false,
                isShowLogin:'true',
                isShowReg: false,
                nickname: "用户登录",
                checked:'1',
                isLogin: this.getToken(),
                squareLink: [
                    {
                        text: "我的课程",
                        show: true,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/mycourse01.png",
                        link: 'handleClickLearning'
                    },
                    {
                        text: "我的笔记",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/mynote01.png",
                        link: 'handleClickType'
                    },
                    {
                        text: "课程答疑",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/myquestion01.png",
                        link: 'handleClickType'
                    }
                ],
                horizontalLink: [
                    {
                        text: "我的订单",
                        desc: "订单详情",
                        show: true,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-myorder.png",
                        link: 'handleClickOrderList'
                    },
                    // {
                    //     text: "我的收藏",
                    //     desc: "",
                    //     show: false,
                    //     photo: require('../assets/images/icon-mynote.png'),
                    //     link: 'handleClickType'
                    // },
                    {
                        text: "我的题库",
                        desc: "",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-tiku.png",
                        link: 'handleClickQuestion'
                    },
                    {
                        text: "我的校区",
                        desc: "",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-branch.png",
                        link: 'handleClickSchool'
                    },
                    {
                        text: "切换考试",
                        desc: "",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-change.png",
                        link: 'handleClickProject'
                    },
                    {
                        text: "意见反馈",
                        desc: "",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-feedback.png",
                        link: 'handleClickType'
                    },
                    {
                        text: "帮助中心",
                        desc: "",
                        show: false,
                        photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-help.png",
                        link: 'courseHelp'
                    },
                    // {
                    //     text: "设置",
                    //     desc: "修改密码",
                    //     show: false,
                    //     photo: "https://cdnoss.ksyt.com.cn/wxImages/assets/images/icon-setting.png",
                    //     link: 'handleSetting'
                    // }
                ],
                redirectUrl: '',
                branchName: '',
                subject_name: '',
                tab_id: 1
            }
        },
        created() {
            this.redirectUrl = this.$route.query.redirect;
            this.isShowModal = this.$route.query.isShowModal ? this.$route.query.isShowModal : false;
            // 组件刚创建的时候需要判断用户是否登录,判断localStorage有没有token值
            // 如果有就应该天上用户信息
            let token = localStorage.getItem("token")
            if (token) {
                // 表示已经有登录状态,就要渲染用户信息
                // let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                // this.nickname = userInfo.nickname;
                // this.avatarSrc = userInfo.avatar;
                this.onInfo();
            }
            this.title = this.getBranchCompany()
        },
        methods: {
            servicesBtn(){
                let branch = this.getBranch();
                this.$router.push('/websiteInfo?branch='+branch+'&type=1')
                // this.$router.push('/freeAudition11')
                console.log("免费试听按钮被点击");
            },
            agreementBtn(){
                let branch = this.getBranch();
                this.$router.push('/websiteInfo?branch='+branch+'&type=2')
                // this.$router.push('/freeAudition11')
                console.log("免费试听按钮被点击");
            },
            onSubmit(values) {
                let that = this;
                // console.log('submit', values);
                let branch = that.getBranch();
                let username = values["usernameLogin"];
                let pwd = values["passwordLogin"];
                let agreement = values["agreement"];
                if(agreement == '') {
                    Toast.fail('请阅读并同意服务条款和协议');
                }
                GoLogin({username, pwd, appid: branch}).then(res => {
                    if (res.data.status == 1) {
                        // 登录成功了
                        // 1.提示框提示登录成功
                        Toast.success('登录成功');
                        // 2.把token保存到本地存储
                        localStorage.setItem("token", res.data.data.jwt);
                        localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo));
                        localStorage.setItem("branchId", res.data.data.userInfo.branchId);
                        localStorage.setItem("adminId", res.data.data.userInfo.adminId);
                        localStorage.setItem("groupId", res.data.data.userInfo.groupId);
                        localStorage.setItem("subjectId", res.data.data.userInfo.subjectId);
                        localStorage.setItem("title", res.data.data.userInfo.title);
                        // that.loginTime();
                        that.nickname = res.data.data.userInfo.username;
                        // if(redirectUrl) {
                        //     that.$router.push({
                        //         name:redirectUrl,
                        //         params:{
                        //
                        //         }
                        //     })
                        // }
                        // that.avatarSrc = res.data.data.userInfo.avatar;
                        // 3.1s后关闭模态框
                        setTimeout(() => {
                            that.isShowModal = !this.isShowModal;
                            // 4.把拿到的userInfo填写到页面上
                            that.nickname = res.data.data.userInfo.username;
                            that.isLogin = that.getToken();
                            // that.avatarSrc = res.data.data.userInfo.avatar;
                            that.onInfo();
                        }, 1000);

                    } else {
                        Toast.fail(res.data.msg);
                    }
                })
            },
            onRegister(values) {
                let that = this;
                console.log('submit', values);

                let username = values["usernameRegister"];
                let pwd = values["passwordRegister"];
                let pwd2 = values["passwordRegister2"];
                let agreement = values["agreement2"];
                if(agreement == '') {
                    Toast.fail('请阅读并同意服务条款和协议');
                    return false;
                }
                if(pwd != pwd2) {
                    Toast.fail('两次密码不一致');
                    return false;
                }
                let branchId = this.getBranch();
                let groupId = this.getGroupId();
                let subjectId = this.getSubjectId();
                let adminId = this.getAdminId();
                GoRegister({username, pwd,pwd2,branchId,groupId,subjectId,adminId}).then(res => {
                    if (res.data.status == 1) {
                        // 登录成功了
                        // 1.提示框提示登录成功
                        Toast.success(res.data.msg);
                        // 2.把token保存到本地存储
                        localStorage.setItem("token", res.data.data.jwt);
                        localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo));
                        localStorage.setItem("branchId", res.data.data.userInfo.branchId);
                        localStorage.setItem("adminId", res.data.data.userInfo.adminId);
                        localStorage.setItem("groupId", res.data.data.userInfo.groupId);
                        localStorage.setItem("subjectId", res.data.data.userInfo.subjectId);
                        that.nickname = res.data.data.userInfo.username;
                        // that.avatarSrc = res.data.data.userInfo.avatar;
                        // 3.1s后关闭模态框
                        setTimeout(() => {
                            that.isShowModal = !this.isShowModal;
                            // 4.把拿到的userInfo填写到页面上
                            that.nickname = res.data.data.userInfo.username;
                            // that.avatarSrc = res.data.data.userInfo.avatar;
                            that.isLogin = that.getToken();
                            that.onInfo();
                        }, 1000);

                    } else {
                        Toast.fail(res.data.msg);
                    }
                })
            },
            openModal() {
                // 判断用户是否登录,如果登录就直接return
                // 没有登录就取反
                let token = localStorage.getItem("token")
                if (token) {
                    return
                }
                this.isShowModal = !this.isShowModal
            },
            onInfo() {
                let that = this;
                onInfo({})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.nickname = res.data.data.userid;
                            that.branchName = res.data.data.branch_name;
                            that.subject_name = res.data.data.subject_name;
                            if(res.data.data.img) {
                                that.avatarSrc = res.data.data.img;
                            }

                        }
                    })
                    .catch(err => {
                        Toast.fail('操作错误');
                    })
            },
            clickTab(name,title) {
                this.tab_id = name
            },
            clickFn(){
                if(!this.checkRadioFlag){
                    this.checked = '';
                }
                this.checkRadioFlag = false;
            },
            changeFn(){
                this.checkRadioFlag = true;
            },
            registerFn(){
                this.isShowLogin = !this.isShowLogin;
                this.isShowReg = !this.isShowReg;
            },
            loginOut() {
                localStorage.removeItem('token');
                localStorage.removeItem('userInfo');
                // localStorage.clear();
                Toast.success('退出成功');
                console.log('测试退出');
                // console.log(this.loginTimer);
                clearInterval(this.loginTimer)
                this.loginTimer = null;
                this.$router.push('Index')
            },

            handler(link) {
                this[link]()
            },
            handleSetting(){
                // this.$router.push('/courseHelp')
                Toast('功能暂未上线')
            },
            courseHelp(){
                this.$router.push('/courseHelp')
            },
            handleClickLearning(){
                this.$router.push('/learning')
                console.log("搜索按钮被点击");
            },
            handleClickOrderList(){
                this.$router.push('/orderList')
                console.log("搜索按钮被点击");
            },
            handleClickSchool(){
                this.$router.push('/mySchool')
                console.log("搜索按钮被点击");
            },
            handleClickQuestion(){
                this.$router.push('/Question')
                console.log("搜索按钮被点击");
            },
            handleClickProject(){
                this.$router.push('/index/project')
                console.log("搜索按钮被点击");
            }
            
        },
    }
</script>

<style lang="less" scoped>
    .login-modal {
        width: 100%;
        height: 110%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        bottom: -10rem;

        .login-form {
            width: 90%;
            background-color: #fff;
            padding-bottom: .2rem;
            position: absolute;
            top: 10%;
            left: 50%;
            margin-left: -45%;
            box-sizing: border-box;
            border-radius: 1rem;
        }
        .loginTitle{
            font-size: 2.4rem;
            height: 6rem;
            line-height: 6rem;
            text-align: center;
            margin: 1rem 0 2rem 0;
        }

        .close-modal {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
        }

        /* .van-form{
            width: 90%;
            position: absolute;
            top: 40vh;
            left: 50%;
            margin-left: -45%;
            padding: .1rem;
            box-sizing: border-box;
            background: #fff;
        } */
    }

    .van-row {
        height: 16rem;
        padding: 0.5rem 2rem;
        background-image: url('https://cdnoss.ksyt.com.cn/wxImages/assets/images/tbg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        color: #fff;
        display: flex;
        align-items: center;

        .van-col {
            line-height: 6rem;

            img {
                width: 10rem;
                border-radius: 50%;
                display: block;
            }

            .van-button--large {
                width: 50%;
            }
        }
    }

    .van-grid {
        padding: 1rem 0.5rem;

        .van-grid-item {
            padding: 0 0.5rem;
            border-radius: 0.5rem;
        }

        .van-icon {
            font-size: 4.4rem;
            padding: 1rem;
        }

        .textTitle {
            margin-bottom: 1.5rem;
            font-size: 1.6rem;
            color: #333333;
        }
    }

    .horizontalLink {
        .van-cell {
            padding: 1rem;
        }

        .van-icon {
            font-size: 2.2rem;
            padding: 0 0.5rem;
        }

        .custom-title {
            font-size: 1.8rem;
        }

        .value-title {
            font-size: 1.8rem;
        }
    }

    .signOut {
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 10rem;

        .van-button {
            width: 90%;
        }
    }
    .register{
        margin: 2rem;
        .van-button{
            border-width: 0;
            font-size: 1.8rem !important;
        }
        .text{
            color: #1989fa;
        }
    }


</style>
